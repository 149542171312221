.step-four {
  height: 100%;
  width: 100%;

  p {
    margin: 0;
  }
  .table {
    margin: auto;
    height: 48vh;
    overflow: auto;
    width: 100%;
  }
  .new-users {
    width: 100%;
    padding-bottom: 1em;

    .column-labels {
      display: flex;
      flex-direction: row;
      padding-left: 1em;
      padding-right: 1em;
      span {
        font-size: 14px;
        color: #a1a1a1;
      }
    }

    .pad-right {
      padding-right: 1em;
    }

    .names {
      width: 20%;
    }

    .email {
      width: 30%;
    }

    .school {
      width: 20%;
      .Mui-disabled {
        background-color: #ffffff;
        font-size: 14px;
      }
    }

    .grade {
      width: 6%;
    }

    .trash {
      width: 4%;
      padding-left: 1em;
    }

    .display-rows {
      display: flex;
      flex-direction: row;
      border-top: 1px solid #ccc;
      font-size: 14px;
      padding: 1em;
    }
    h3 {
      color: #2f2f51;
    }
    h4 {
      text-align: center;
    }
  }

  .no-margin {
    margin: 0;
  }

  .new-users-counter-banner {
    padding-top: 1em;
    display: flex;

    .newUsers {
      display: flex;
      width: 100%;
      font-size: 18px;
      font-weight: 700;
      color: #2f2f51;
    }

    .counter {
      display: flex;
      width: 100%;
      justify-content: end;
      padding-right: 2em;
      font-weight: 700;
      font-size: 16px;
      color: #db1e36;
    }
  }
  .stepper-nav {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    position: absolute;
    width: auto;
    padding: 30px 50px;
    bottom: 0;
    right: 0;
    button {
      height: 3em;
      width: 11.2em;
    }
  }
}
